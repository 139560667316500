<template>
  <div :class="className">
    <van-nav-bar
      title="工单管理"
      :border="false"
      left-arrow
      @click-left="$router.back()"
    >
    </van-nav-bar>
    <van-list :class="`${className}__list`">
      <van-cell
        v-for="(item, idx) in workorderConf"
        :key="idx"
        is-link
        clickable
        :title="item.title"
        :class="`${className}__list-item`"
        :to="{ name: item.routerName }"
        :border="idx !== workorderConf.length - 1"
      >
        <template #title>
          <van-icon
            :name="item.icon"
            :color="item.iconColor"
            class-prefix="iconfont"
            class="van-cell__left-icon"
          />
          <span>{{ item.title }}</span>
        </template>
      </van-cell>
    </van-list>
  </div>
</template>

<script>
export default {
  data() {
    return {
      className: "workorder-dispatch",
      workorderConf: [
        {
          icon: "gongdanchaxun",
          iconColor: "#18C695",
          title: "工单查询",
          routerName: "workorderList"
        },
        // {
        //   icon: "weixiujiluchaxun",
        //   iconColor: "#1676FF",
        //   title: "维修记录查询",
        //   routerName: "maintenanceRecords"
        // },
        {
          icon: "daiwochuli",
          iconColor: "#F3961C",
          title: "待我处理",
          routerName: "todoList"
        }
      ]
    };
  }
};
</script>

<style lang="scss">
.workorder-dispatch {
  &__list {
    .van-cell::after {
      border-bottom: 1px solid #ebebeb;
    }
  }
  &__list-item {
    padding: 15px 20px;
    .van-cell__left-icon {
      font-size: 24px;
      margin-right: 8px;
      vertical-align: -4px;
    }
    span {
      color: #333333;
      font-size: 15px;
    }
  }
}
</style>
